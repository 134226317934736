var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "config-modalidade new-tabs" },
    [
      _c("vertical-tabs-components", { attrs: { tabs: _vm.tabs } }, [
        _c(
          "div",
          {
            attrs: { slot: "tab-content-" + _vm.tabs[0].tab },
            slot: "tab-content-" + _vm.tabs[0].tab
          },
          [
            _c("div", { staticClass: "vx-row" }, [
              _c(
                "div",
                { staticClass: "vx-col w-1/2" },
                [
                  _c("span", { staticClass: "mt-3" }, [
                    _vm._v("Modalidade "),
                    _c("b", { staticStyle: { color: "red" } }, [_vm._v("*")])
                  ]),
                  _c(
                    "el-select",
                    {
                      staticClass: "mt-3",
                      attrs: {
                        filterable: "",
                        placeholder: "Escolha a modalidade"
                      },
                      model: {
                        value: _vm.modalidadeSelecionada,
                        callback: function($$v) {
                          _vm.modalidadeSelecionada = $$v
                        },
                        expression: "modalidadeSelecionada"
                      }
                    },
                    _vm._l(_vm.modalidades, function(item) {
                      return _c("el-option", {
                        key: item.Id,
                        attrs: { label: item.Name, value: item.Id }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "vx-col w-1/3" },
                [
                  _c("span", { staticClass: "mt-3" }, [
                    _vm._v("Tipo de Índice "),
                    _c("b", { staticStyle: { color: "red" } }, [_vm._v("*")])
                  ]),
                  _c(
                    "el-select",
                    {
                      staticClass: "mt-3",
                      attrs: { filterable: "", placeholder: "Escolha o tipo" },
                      model: {
                        value: _vm.indiceSelecionada,
                        callback: function($$v) {
                          _vm.indiceSelecionada = $$v
                        },
                        expression: "indiceSelecionada"
                      }
                    },
                    _vm._l(_vm.indiceList, function(item) {
                      return _c("el-option", {
                        key: item.Id,
                        attrs: { label: item.Nome, value: item.Id }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            ]),
            _c(
              "vs-row",
              {
                staticClass: "mt-5",
                attrs: { "vs-type": "flex", "vs-justify": "end" }
              },
              [
                _c(
                  "vs-col",
                  {
                    attrs: {
                      "vs-type": "flex",
                      "vs-justify": "end",
                      "vs-align": "center",
                      "vs-lg": "3",
                      "vs-sm": "12"
                    }
                  },
                  [
                    _vm.modalidadeSelecionada != null &&
                    _vm.indiceSelecionada != null
                      ? _c(
                          "vs-button",
                          {
                            staticClass: "button-primary text-center",
                            on: {
                              click: function($event) {
                                return _vm.update()
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n            Salvar Alterações\n          "
                            )
                          ]
                        )
                      : _c(
                          "vs-button",
                          {
                            staticClass: "button-primary text-center",
                            attrs: { disabled: "" }
                          },
                          [
                            _vm._v(
                              "\n            Salvar Alterações\n          "
                            )
                          ]
                        )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }